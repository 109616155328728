<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        dark
        :color="typeOfApportionment.color"
      >
        <v-toolbar-title>
          {{ typeOfApportionment.description }}

          <span
            v-if="apportionment"
            class="body-2"
          >
            <i>Editando #{{ apportionment.date | date }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-form class="mt-2">
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{}">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="date"
                      >
                        <v-text-field
                          v-model="formatedDate"
                          v-mask="'##/##/####'"
                          label="Data"
                          hide-details="auto"
                          :error-messages="errors"
                          append-icon="mdi-calendar"
                          @blur="fields.date = dateFormatIso(formatedDate)"
                          @click:append="dateMenu = true"
                        />
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="fields.date"
                      no-title
                      class="ma-0"
                      @input="dateMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="estimatedValue"
                  >
                    <v-text-field
                      v-model="fields.estimatedValue"
                      v-currency="{
                        currency: 'BRL',
                        locale: 'pt-BR',
                        distractionFree: false,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false
                      }"
                      hide-details="auto"
                      label="Valor Estimado"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="realizedValue"
                  >
                    <v-text-field
                      v-model="fields.realizedValue"
                      v-currency="{
                        currency: 'BRL',
                        locale: 'pt-BR',
                        distractionFree: false,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false
                      }"
                      hide-details="auto"
                      label="Valor Realizado"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loading"
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import dateFormat from '@/utils/date-format'
  import apportionmentsApi from '@/api/apportionments'
  import currencyFilter from '@/utils/filters/currency'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      typeOfApportionment: {
        type: Object,
        default: () => { },
      },
      apportionment: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        loadingGet: false,
        dateMenu: false,
        formatedDate: null,
        fields: {},
        dateFormatIso: dateFormat.iso,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.init()
      },
      'fields.date': {
        immediate: true,
        handler (newVal) {
          this.formatedDate = dateFormat.ptBr(newVal)
        },
      },
    },

    methods: {
      init () {
        if (this.apportionment) {
          this.fields = {
            date: this.apportionment.date,
            estimatedValue: currencyFilter(this.apportionment.estimatedValue),
            realizedValue: currencyFilter(this.apportionment.realizedValue),
          }
        } else {
          this.fields = {
            date: dayjs().format('YYYY-MM-DD'),
            estimatedValue: 'R$ 0,00',
            realizedValue: 'R$ 0,00',
          }
        }
      },

      async save () {
        this.loading = true
        this.$refs.observer.reset()

        try {
          const apportionment = { ...this.fields }

          apportionment.typeOfApportionmentId = this.typeOfApportionment.id
          apportionment.estimatedValue = parseInt(apportionment.estimatedValue.replace(/\D/g, '')) / 100
          apportionment.realizedValue = parseInt(apportionment.realizedValue.replace(/\D/g, '')) / 100

          if (this.apportionment) {
            await apportionmentsApi.update(this.apportionment.id, apportionment)
          } else {
            await apportionmentsApi.insert(apportionment)
          }

          this.$snackbar.show({ color: 'success', message: 'Salvo com sucesso' })
          this.show = false
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loading = false
        }
      },
    },

  }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","dark":"","color":_vm.typeOfApportionment.color}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.typeOfApportionment.description)+" "),(_vm.apportionment)?_c('span',{staticClass:"body-2"},[_c('i',[_vm._v("Editando #"+_vm._s(_vm._f("date")(_vm.apportionment.date)))])]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"mt-2"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data","hide-details":"auto","error-messages":errors,"append-icon":"mdi-calendar"},on:{"blur":function($event){_vm.fields.date = _vm.dateFormatIso(_vm.formatedDate)},"click:append":function($event){_vm.dateMenu = true}},model:{value:(_vm.formatedDate),callback:function ($$v) {_vm.formatedDate=$$v},expression:"formatedDate"}})]}}],null,true)})]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.fields.date),callback:function ($$v) {_vm.$set(_vm.fields, "date", $$v)},expression:"fields.date"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"estimatedValue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
                      currency: 'BRL',
                      locale: 'pt-BR',
                      distractionFree: false,
                      autoDecimalMode: true,
                      valueRange: { min: 0 },
                      allowNegative: false
                    }),expression:"{\n                      currency: 'BRL',\n                      locale: 'pt-BR',\n                      distractionFree: false,\n                      autoDecimalMode: true,\n                      valueRange: { min: 0 },\n                      allowNegative: false\n                    }"}],attrs:{"hide-details":"auto","label":"Valor Estimado","error-messages":errors},model:{value:(_vm.fields.estimatedValue),callback:function ($$v) {_vm.$set(_vm.fields, "estimatedValue", $$v)},expression:"fields.estimatedValue"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"realizedValue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
                      currency: 'BRL',
                      locale: 'pt-BR',
                      distractionFree: false,
                      autoDecimalMode: true,
                      valueRange: { min: 0 },
                      allowNegative: false
                    }),expression:"{\n                      currency: 'BRL',\n                      locale: 'pt-BR',\n                      distractionFree: false,\n                      autoDecimalMode: true,\n                      valueRange: { min: 0 },\n                      allowNegative: false\n                    }"}],attrs:{"hide-details":"auto","label":"Valor Realizado","error-messages":errors},model:{value:(_vm.fields.realizedValue),callback:function ($$v) {_vm.$set(_vm.fields, "realizedValue", $$v)},expression:"fields.realizedValue"}})]}}])})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loading},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }